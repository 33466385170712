<template>
  <div class="pa-6 d-none">
    <v-btn depressed :disabled="success" @click="onCompany" class="mr-3">
      Create Company
    </v-btn>
    <v-btn :disabled="!success" depressed @click="onOwner" color="primary">
      Create Owner
    </v-btn>
    <br />
    <br />

    CompanyId: {{ docId }}
  </div>
</template>

<script>
import { db, functions } from "@/firebase";
import { addDoc, collection } from "@firebase/firestore";
import { httpsCallable } from "@firebase/functions";

export default {
  data: () => ({
    docId: null,
    success: false,
  }),

  methods: {
    async onCompany() {
      const item = {
        name: "FÔRET CLUB",
        address: "Ernesto Pinto Lagarrigue 3295",
        warning: true,
        disabled: false,
      };

      const docRef = await addDoc(collection(db, "companies"), item);
      this.docId = docRef.id;
      this.success = true;
    },
    async onOwner() {
      const addMessage = httpsCallable(functions, "createUser");

      const item = {
        email: "owner@demo.cl",
        password: "95055012",
        displayName: "Owner Demo",
        disabled: false,
        customClaims: {
          owner: true,
          companyId: this.docId,
          companyName: "FÔRET CLUB",
        },
      };

      await addMessage(item)
        .then((result) => {
          console.debug("ABOUT: ", result);
        })
        .catch((err) => {
          console.debug("about.vue: ", err);
        });

      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      this.$router.push({ path: "/" });
      return;
    }
  },
};
</script>